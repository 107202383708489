import { ReactElement, memo } from 'react';

//components
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Instruction } from './Instruction';
import { TestAreaContainer } from './TestAreaContainer';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import CircularProgress from '@mui/material/CircularProgress';

//hooks
import { useTest } from '../hooks/useTest';
import { useLogoutHandler } from 'hooks/useLogoutHandler';

import { VIEW } from 'modules/test/constants';

const TestContainer = (): ReactElement | null => {
  const { currentView, onAction, loading, error, testConfig, isTestSubmitting } = useTest();
  const logoutHandler = useLogoutHandler('../login');

  if (currentView === VIEW.EXAM_COMPLETE) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: theme => theme.spacing(9),
          boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.03)',
          background: '#FFFFFF',
          width: theme => theme.spacing(144),
          height: theme => theme.spacing(120),
          alignItems: 'center',
          justifyContent: 'center',
        }}
        paddingTop={15}
        paddingBottom={15}
        paddingLeft={12}
        paddingRight={12}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            fontFamily: 'lato',
            color: '#386FB2',
            fontWeight: 800,
            fontSize: '26px',
            lineHeight: '24px',
          }}
          marginBottom={18}
        >
          Test Successfully completed!
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            marginTop: theme => theme.spacing(5),
            borderRadius: theme => theme.spacing(3),
            backgroundColor: '#386FB2',
            height: theme => theme.spacing(10),
          }}
          onClick={logoutHandler}
        >
          <Typography variant="h4" sx={{ textTransform: 'none' }}>
            Logout
          </Typography>
        </Button>
      </Box>
    );
  }

  if (currentView === VIEW.INSTRUCTION) {
    return <Instruction onAction={onAction} hasTestStarted={!!testConfig} />;
  }

  if (loading) {
    return <CircularProgress size={75} />;
  }

  if (error || !testConfig) {
    return (
      <Stack direction="column" spacing={4} sx={{ alignItems: 'center' }}>
        <ErrorSharpIcon
          sx={{
            height: theme => theme.spacing(16),
            width: theme => theme.spacing(16),
          }}
        />
        <Typography variant="h3">{error?.message ?? 'Something went wrong, please refresh the page!'}</Typography>
      </Stack>
    );
  }

  return <TestAreaContainer testConfig={testConfig} onAction={onAction} isTestSubmitting={isTestSubmitting} />;
};

const MemoizedTestContainer = memo(TestContainer);
MemoizedTestContainer.displayName = 'MemoizedTestContainer';

export { MemoizedTestContainer as TestContainer };
