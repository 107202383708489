import { useState, useCallback } from 'react';
import { convertAnswersToDTO } from '../utils';

import { VIEW } from '../constants';
import * as ACTION_TYPES from '../actionTypes';

import { TestContainerAction, TestConfig } from '../types';
import { apiClient } from 'services/api';
import { useNotificationContext } from 'context/NotificationContext';
import { useTestLazyQuery } from './useTestLazyQuery';
import { set } from 'lodash';

const useTest = (): {
  loading: boolean;
  error?: any;
  currentView: typeof VIEW.EXAMS | typeof VIEW.INSTRUCTION | typeof VIEW.EXAM_COMPLETE;
  onAction: (action: TestContainerAction) => void;
  testConfig?: TestConfig;
  isTestSubmitting: boolean;
} => {
  const [isTestSubmitting, setTestSubmissionLoading] = useState(false);
  const { loading, error, testConfig, fetchTestConfig } = useTestLazyQuery();
  const { onSuccess, onError } = useNotificationContext();

  const [currentView, setCurrentView] = useState<
    typeof VIEW.EXAMS | typeof VIEW.INSTRUCTION | typeof VIEW.EXAM_COMPLETE
  >(VIEW.INSTRUCTION);

  const onAction = useCallback(
    (action: TestContainerAction): void => {
      switch (action.type) {
        case ACTION_TYPES.START_TEST: {
          fetchTestConfig();
          setCurrentView(VIEW.EXAMS);
          break;
        }
        case ACTION_TYPES.TOGGLE_VIEW: {
          setCurrentView(currentView => (currentView === VIEW.EXAMS ? VIEW.INSTRUCTION : VIEW.EXAMS));
          break;
        }
        case ACTION_TYPES.SUBMIT_TEST: {
          const answers = action.payload.answers;
          const adaptedAnswersDTO = convertAnswersToDTO(answers);
          setTestSubmissionLoading(true);
          apiClient.post('api/v1/answers', { answers: adaptedAnswersDTO }).then(
            () => {
              onSuccess('Answers saved successfully!');
              localStorage.setItem('answerState', '');
              setTestSubmissionLoading(false);
              setCurrentView(VIEW.EXAM_COMPLETE);
            },
            () => {
              onError('Error occurred while saving answers! Please click submit!');
              setTestSubmissionLoading(false);
            }
          );
          break;
        }
        default: {
          break;
        }
      }
    },
    [setCurrentView, onSuccess, onError, fetchTestConfig]
  );

  return {
    loading,
    error,
    testConfig,
    currentView,
    onAction,
    isTestSubmitting,
  };
};

export { useTest };
