import { useCallback, ReactElement } from 'react';
import _isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FileUploader } from 'react-drag-drop-files';
import { ImageCaptureModal } from './ImageCaptureModal';

import { Header } from 'components/Header';
import { TextInput } from 'components/TextInput';
import { AsyncSelect } from 'components/AsyncSelect';
import { Select } from 'components/Select';

//Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useSignupHandler } from '../hooks/useSignupHandler';
import { useForm } from 'hooks/useForm';
import { useNavigate } from 'react-router-dom';
import { useVisibilityToggle } from 'hooks/useVisibilityToggle';

import { apiClient } from 'services/api';

import {
  FORM_FIELD_ENUM,
  REQUIRED_FIELDS,
  EMPLOYEE_REFERENCE_ID,
  REFERENCE_OPTIONS,
} from '../constants';

const TEXT_INPUT_DEFAULT_PROPS = {
  fullWidth: true,
  margin: 'normal' as const,
  variant: 'outlined' as const,
  size: 'medium' as const,
};

const INTERVIEW_SELECTION_TEXT_INPUT_PROPS = {
  ...TEXT_INPUT_DEFAULT_PROPS,
  label: 'Which Interview Are You Appearing For?',
  required: true,
};
const REFERENCE_SELECTION_PROPS = {
  ...TEXT_INPUT_DEFAULT_PROPS,
  label: 'Reference',
  required: true,
};

const fetchDepartments = async (): Promise<{ id: string; name: string }[]> => {
  const response = await apiClient.get('api/departments');
  return response.data?.data;
};

type StringAnyMap = { [x: string]: any };

const validator = (value: StringAnyMap): StringAnyMap | undefined => {
  let errors = REQUIRED_FIELDS.reduce(
    (acc, fieldId) => (value[fieldId] ? acc : { ...acc, [fieldId]: "Field can't be empty" }),
    {},
  );
  if (
    value[FORM_FIELD_ENUM.REFERENCE] === EMPLOYEE_REFERENCE_ID &&
    !value[FORM_FIELD_ENUM.EMPLOYEE_REFERENCE]
  ) {
    errors = {
      ...errors,
      [FORM_FIELD_ENUM.EMPLOYEE_REFERENCE]: 'Employee Reference Name is mandatory!',
    };
  }

  return _isEmpty(errors) ? undefined : errors;
};

const CV_FILE_FORMAT = ['pdf'];
const PHOTO_FORMAT = ['jpeg', 'png', 'jpg'];

const WebSignup = (): ReactElement => {
  const { onSignup: handleSignup, loading, error } = useSignupHandler();
  const navigate = useNavigate();

  const onSubmitSuccess = useCallback(() => navigate('../login'), [navigate]);

  const { values, errors, handleChange, handleFileChange, onSubmit } = useForm({
    handleSubmit: handleSignup,
    onSubmitSuccess,
    initialState: {
      [FORM_FIELD_ENUM.JOINING_DATE]: new Date().toISOString().split('T')[0],
    },
    validator,
  });

  const handleCVUpload = useCallback(
    (file: File) => handleFileChange(FORM_FIELD_ENUM.CV, file),
    [handleFileChange],
  );

  const handlePhotoUpload = useCallback(
    (file: File) => handleFileChange(FORM_FIELD_ENUM.PHOTO, file),
    [handleFileChange],
  );

  const {
    isOpen: shouldShowPassword,
    open: showPassword,
    close: hidePassword,
  } = useVisibilityToggle(false);

  const {
    isOpen: shouldShowCaptureImageModal,
    open: showImageCapture,
    close: hideImageCapture,
  } = useVisibilityToggle(false);

  const ReferenceInputEl = (
    <Select
      id={FORM_FIELD_ENUM.REFERENCE}
      value={values[FORM_FIELD_ENUM.REFERENCE]}
      options={REFERENCE_OPTIONS}
      error={!!errors?.[FORM_FIELD_ENUM.REFERENCE]}
      textInputProps={REFERENCE_SELECTION_PROPS}
      onSelect={handleChange}
    />
  );

  return (
    <Box
      component="main"
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
      }}
    >
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '1 1 0',
          marginLeft: (theme) => theme.spacing(18),
          marginRight: (theme) => theme.spacing(18),
          marginTop: (theme) => theme.spacing(4),
          marginBottom: (theme) => theme.spacing(4),
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: (theme) => theme.spacing(6.5),
            lineHeight: (theme) => theme.spacing(6),
            color: '#386FB2',
            fontWeight: 800,
            fontFamily: 'Lato',
          }}
        >
          Sign Up
        </Typography>
        <Stack
          direction="row"
          gap={9}
          sx={{
            width: '100%',
            marginTop: (theme) => theme.spacing(7),
          }}
        >
          <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column' }}>
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.NAME}
              value={values[FORM_FIELD_ENUM.NAME]}
              error={!!errors?.[FORM_FIELD_ENUM.NAME]}
              label="Name of Candidate"
              autoFocus
              size="medium"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.MOBILE}
              value={values[FORM_FIELD_ENUM.MOBILE]}
              error={!!errors?.[FORM_FIELD_ENUM.MOBILE]}
              label="Phone number"
              size="medium"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.EMAIL}
              value={values[FORM_FIELD_ENUM.EMAIL]}
              error={!!errors?.[FORM_FIELD_ENUM.EMAIL]}
              label="Email ID"
              autoComplete="email"
              size="medium"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.PASSWORD}
              value={values[FORM_FIELD_ENUM.PASSWORD]}
              error={!!errors?.[FORM_FIELD_ENUM.PASSWORD]}
              label="Password"
              size="medium"
              onChange={handleChange}
              type={shouldShowPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {shouldShowPassword ? (
                      <VisibilityOff sx={{ cursor: 'pointer' }} onClick={hidePassword} />
                    ) : (
                      <Visibility sx={{ cursor: 'pointer' }} onClick={showPassword} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <AsyncSelect
              id={FORM_FIELD_ENUM.DEPARTMENT_ID}
              fetchOptions={fetchDepartments}
              value={values[FORM_FIELD_ENUM.DEPARTMENT_ID]}
              error={errors?.[FORM_FIELD_ENUM.DEPARTMENT_ID]}
              textInputProps={INTERVIEW_SELECTION_TEXT_INPUT_PROPS}
              onSelect={handleChange}
            />
            <TextInput
              fullWidth
              required
              margin="normal"
              type="number"
              id={FORM_FIELD_ENUM.NOTICE_PERIOD}
              value={values[FORM_FIELD_ENUM.NOTICE_PERIOD]}
              error={!!errors?.[FORM_FIELD_ENUM.NOTICE_PERIOD]}
              label="Notice period (Months)"
              size="medium"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.EXPERIENCE}
              value={values[FORM_FIELD_ENUM.EXPERIENCE]}
              error={!!errors?.[FORM_FIELD_ENUM.EXPERIENCE]}
              label="Experience ( No. Of Years )"
              size="medium"
              type="number"
              onChange={handleChange}
            />
            <Box sx={{ width: '100%', marginTop: (theme) => theme.spacing(4) }}>
              <FileUploader
                name={FORM_FIELD_ENUM.PHOTO}
                handleChange={handleCVUpload}
                types={CV_FILE_FORMAT}
                required
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: (theme) => theme.spacing(3),
                    height: (theme) => theme.spacing(14),
                    border: '1px dotted ',
                    borderColor: (theme) =>
                      !errors?.[FORM_FIELD_ENUM.CV] ? '#386FB2' : theme.palette.error.main,
                    borderRadius: (theme) => theme.spacing(3),
                    fontSize: (theme) => theme.spacing(4),
                    color: (theme) =>
                      !errors?.[FORM_FIELD_ENUM.CV]
                        ? 'rgba(102, 102, 102)'
                        : theme.palette.error.main,
                  }}
                >
                  <AttachFileIcon
                    sx={{
                      marginLeft: (theme) => theme.spacing(3),
                      color: (theme) =>
                        !errors?.[FORM_FIELD_ENUM.CV]
                          ? 'rgba(102, 102, 102)'
                          : theme.palette.error.main,
                      height: (theme) => theme.spacing(5),
                      width: (theme) => theme.spacing(5),
                    }}
                  />
                  {!errors?.[FORM_FIELD_ENUM.CV]
                    ? !values[FORM_FIELD_ENUM.CV]
                      ? 'Upload or drag a CV file to upload!*'
                      : 'CV uploaded!'
                    : 'CV is mandatory!'}
                </Box>
              </FileUploader>
            </Box>
          </Box>
          <Box
            sx={{
              flex: '1 1 0',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.HIGHEST_QUALIFICATION}
              value={values[FORM_FIELD_ENUM.HIGHEST_QUALIFICATION]}
              error={!!errors?.[FORM_FIELD_ENUM.HIGHEST_QUALIFICATION]}
              label="Highest Qualification"
              size="medium"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.CURRENT_SALARY}
              value={values[FORM_FIELD_ENUM.CURRENT_SALARY]}
              error={!!errors?.[FORM_FIELD_ENUM.CURRENT_SALARY]}
              label="Monthly Current Salary"
              size="medium"
              type="number"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.EXPECTED_SALARY}
              value={values[FORM_FIELD_ENUM.EXPECTED_SALARY]}
              error={!!errors?.[FORM_FIELD_ENUM.EXPECTED_SALARY]}
              label="Monthly Expected Salary"
              size="medium"
              type="number"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.REASON_TO_LEAVE}
              value={values[FORM_FIELD_ENUM.REASON_TO_LEAVE]}
              error={!!errors?.[FORM_FIELD_ENUM.REASON_TO_LEAVE]}
              label="Reason to resign"
              size="medium"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              id={FORM_FIELD_ENUM.JOINING_DATE}
              value={values[FORM_FIELD_ENUM.JOINING_DATE]}
              error={!!errors?.[FORM_FIELD_ENUM.JOINING_DATE]}
              label="Tentative Joining Date"
              size="medium"
              type="date"
              onChange={handleChange}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              id={FORM_FIELD_ENUM.SOFTWARES}
              value={values[FORM_FIELD_ENUM.SOFTWARES]}
              error={!!errors?.[FORM_FIELD_ENUM.SOFTWARES]}
              label="Software Known"
              size="medium"
              onChange={handleChange}
            />
            {values[FORM_FIELD_ENUM.REFERENCE] === EMPLOYEE_REFERENCE_ID ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'grid',
                  'grid-template-columns': '1fr 1fr',
                  gap: '8px',
                }}
              >
                {ReferenceInputEl}
                <TextInput
                  fullWidth
                  margin="normal"
                  required
                  id={FORM_FIELD_ENUM.EMPLOYEE_REFERENCE}
                  value={values[FORM_FIELD_ENUM.EMPLOYEE_REFERENCE]}
                  error={!!errors?.[FORM_FIELD_ENUM.EMPLOYEE_REFERENCE]}
                  label="Employee Reference Name"
                  size="medium"
                  onChange={handleChange}
                />
              </Box>
            ) : (
              ReferenceInputEl
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginTop: (theme) => theme.spacing(4),
              }}
            >
              <Box sx={{ flexGrow: '1' }}>
                <FileUploader
                  name={FORM_FIELD_ENUM.PHOTO}
                  handleChange={handlePhotoUpload}
                  types={PHOTO_FORMAT}
                  required
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: (theme) => theme.spacing(3),
                      height: (theme) => theme.spacing(14),
                      border: '1px dotted ',
                      borderColor: (theme) =>
                        !errors?.[FORM_FIELD_ENUM.PHOTO] ? '#386FB2' : theme.palette.error.main,
                      borderRadius: (theme) => theme.spacing(3),
                      fontSize: (theme) => theme.spacing(4),
                      color: (theme) =>
                        !errors?.[FORM_FIELD_ENUM.PHOTO]
                          ? 'rgba(102, 102, 102)'
                          : theme.palette.error.main,
                    }}
                  >
                    <AttachFileIcon
                      sx={{
                        marginLeft: (theme) => theme.spacing(3),
                        color: (theme) =>
                          !errors?.[FORM_FIELD_ENUM.PHOTO]
                            ? 'rgba(102, 102, 102)'
                            : theme.palette.error.main,
                        height: (theme) => theme.spacing(5),
                        width: (theme) => theme.spacing(5),
                      }}
                    />
                    {!errors?.[FORM_FIELD_ENUM.PHOTO]
                      ? !values[FORM_FIELD_ENUM.PHOTO]
                        ? 'Upload or drag a photo to upload!*'
                        : 'Photo uploaded!'
                      : 'Photo is mandatory!'}
                  </Box>
                </FileUploader>
              </Box>
              <Button
                variant="contained"
                sx={{
                  marginLeft: (theme) => theme.spacing(2),
                  borderRadius: (theme) => theme.spacing(3),
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontSize: (theme) => theme.spacing(3.5),
                    lineHeight: (theme) => theme.spacing(6),
                    textTransform: 'none',
                  }}
                  onClick={showImageCapture}
                >
                  Capture Image
                </Box>
                <ImageCaptureModal
                  isOpen={shouldShowCaptureImageModal}
                  handleClose={hideImageCapture}
                  handleSave={handlePhotoUpload}
                />
              </Button>
            </Box>
          </Box>
        </Stack>
        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{
            marginTop: (theme) => theme.spacing(5),
            borderRadius: (theme) => theme.spacing(3),
            backgroundColor: '#386FB2',
            height: (theme) => theme.spacing(15),
            width: '50%',
          }}
          onClick={onSubmit}
        >
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <Box
              component="span"
              sx={{
                fontSize: (theme) => theme.spacing(4.5),
                fontWeight: 600,
                lineHeight: (theme) => theme.spacing(6),
                textTransform: 'none',
              }}
            >
              Sign up
            </Box>
          )}
        </Button>
        {error ? (
          <Box
            component="span"
            sx={{
              fontSize: (theme) => theme.spacing(4),
              color: 'red',
              marginTop: (theme) => theme.spacing(2),
            }}
          >
            {error?.message ?? 'Something went wrong!'}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export { WebSignup };
