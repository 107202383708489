//lib
import { useCallback, Fragment, ReactNode, HTMLAttributes, SyntheticEvent } from 'react';

//components
import { TextInput } from './TextInput';
import Autocomplete from '@mui/material/Autocomplete';
import { TextFieldProps } from '@mui/material/TextField';
import { Typography } from '@mui/material';

//hooks
import { useVisibilityToggle } from 'hooks/useVisibilityToggle';

type Option = {
  id: string;
  name: string;
};

const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: Option): ReactNode => {
  return (
    <li {...props}>
      <Typography variant="h5">{option.name}</Typography>
    </li>
  );
};

const Select = ({
  id,
  textInputProps,
  options,
  value: selectedValue,
  error,
  onSelect,
}: {
  id: string;
  value: any;
  error: boolean;
  options: Option[];
  textInputProps?: TextFieldProps;
  onSelect: (event: { target: { id: string; value: any } }) => void;
}) => {
  const { isOpen, close, open } = useVisibilityToggle(false);

  const onChange = useCallback(
    (event: SyntheticEvent, value: { id: string; name: string } | null) => {
      onSelect({
        ...event,
        target: {
          id,
          value: value?.id,
        },
      });
    },
    [onSelect, id],
  );

  return (
    <Autocomplete
      id={id}
      open={isOpen}
      onOpen={open}
      onClose={close}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      inputValue={selectedValue}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={false}
      renderOption={renderOption}
      onChange={onChange}
      renderInput={(params) => (
        <TextInput
          label="Asynchronus"
          {...params}
          {...textInputProps}
          InputProps={{
            style: { fontSize: 16 },
            ...params.InputProps,
            endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
          }}
          error={error}
        />
      )}
    />
  );
};

export { Select };
