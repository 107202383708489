import { useAsyncFn } from 'react-use';
import { FORM_FIELD_ENUM, EMPLOYEE_REFERENCE_ID } from '../constants';

import { useNotificationContext } from 'context/NotificationContext';

//apiClient
import { apiClient } from 'services/api';

type StringAnyMap = { [x: string]: any };

export type SignupHandler = (values: { [x: string]: any }) => Promise<void>;

export type ReturnType = {
  onSignup: SignupHandler;
  loading: boolean;
  error?: any;
};

function toFormData(o: StringAnyMap): FormData {
  return Object.entries(o).reduce((d, e) => {
    d.append(...e);
    return d;
  }, new FormData());
}

const useSignupHandler = (): ReturnType => {
  const { onSuccess, onError } = useNotificationContext();

  const [{ loading, error }, onSignup] = useAsyncFn(
    async (values: { [x: string]: any }) => {
      const { employee_reference: employeeName, ...valuesToPick } = values;
      const adaptedValues = {
        ...valuesToPick,
        [FORM_FIELD_ENUM.REFERENCE]:
          valuesToPick[FORM_FIELD_ENUM.REFERENCE] === EMPLOYEE_REFERENCE_ID
            ? `${valuesToPick[FORM_FIELD_ENUM.REFERENCE]}: ${employeeName}`
            : valuesToPick[FORM_FIELD_ENUM.REFERENCE],
        [FORM_FIELD_ENUM.JOINING_DATE]: valuesToPick[FORM_FIELD_ENUM.JOINING_DATE]
          ? valuesToPick[FORM_FIELD_ENUM.JOINING_DATE].split('-').reverse().join('-')
          : undefined,
      };
      try {
        await apiClient.post('api/register', toFormData(adaptedValues), {
          withCredentials: true,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        });
        onSuccess('Signup successful!');
      } catch (e) {
        onError((e as { message: string })?.message || 'Something went wrong!');
        throw e;
      }
    },
    [onSuccess, onError],
    { loading: false },
  );

  return {
    loading,
    error,
    onSignup,
  };
};

export { useSignupHandler };
