import { useCallback, useMemo, memo, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { RightPane } from './RightPane';
import ErrorBoundary from 'components/ErrorBoundary';

import { convertMsToTime } from '../utils';

import { TestAreaAction, Answer, QuestionConfig } from '../types';
import * as ACTION_TYPES from '../actionTypes';
import { LeftPane } from './LeftPane';

const TestAreaView = ({
  remainingTestTime,
  onAction,
  answerState,
  question,
  isTestSubmitting,
}: {
  onAction: (action: TestAreaAction) => void;
  remainingTestTime: number;
  answerState: { questionIndex: number; answers: Answer[] };
  question: QuestionConfig;
  isTestSubmitting: boolean;
}): ReactElement => {
  const answers = answerState.answers;
  const answersLength = answers.length;
  const currentQuestionIndex = answerState.questionIndex;
  const currentAnswer = answers[currentQuestionIndex];
  const toggleView = useCallback(() => onAction({ type: ACTION_TYPES.TOGGLE_VIEW }), [onAction]);

  const birdviewState = useMemo(() => answers.map(answer => ({ id: answer.id, state: answer.state })), [answers]);

  const onClickPrevious = useCallback(() => {
    onAction({
      type: ACTION_TYPES.NAVIGATE_TO_QUESTION,
      payload: {
        questionIndex: currentQuestionIndex - 1,
      },
    });
  }, [currentQuestionIndex, onAction]);

  const onClickNext = useCallback(() => {
    onAction({
      type: ACTION_TYPES.NAVIGATE_TO_QUESTION,
      payload: {
        questionIndex: currentQuestionIndex === answersLength - 1 ? currentQuestionIndex : currentQuestionIndex + 1,
      },
    });
  }, [currentQuestionIndex, onAction, answersLength]);

  const clearSection = useCallback(() => {
    onAction({
      type: ACTION_TYPES.CLEAR_SECTION,
    });
  }, [onAction]);

  const reviewLater = useCallback(() => {
    onAction({ type: ACTION_TYPES.MARK_FOR_REVIEW });
  }, [onAction]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: theme => theme.spacing(12),
          width: '100%',
          background: '#386FB2',
          borderRadius: '0px 0px 12px 12px',
          position: 'sticky',
          top: theme => theme.spacing(17),
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }} marginRight={12}>
          <Box
            sx={{
              color: '#FFFFFF',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <AccessTimeSharpIcon sx={{ height: '20px', width: '20px' }} />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '24px',
              }}
              marginLeft={1}
            >
              Time Left : {convertMsToTime(remainingTestTime)}
            </Typography>
          </Box>
          <Button
            onClick={toggleView}
            sx={{
              color: '#FFFFFF',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: theme => theme.spacing(15),
            }}
          >
            <InfoSharpIcon sx={{ height: '20px', width: '20px' }} />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '24px',
              }}
              marginLeft={1}
            >
              Instructions
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Box sx={{ flexGrow: 1 }} marginTop={6} marginRight={6} marginLeft={12}>
            <ErrorBoundary>
              <LeftPane
                index={answerState.questionIndex + 1}
                question={question}
                onAction={onAction}
                answer={answers[answerState.questionIndex]}
              />
            </ErrorBoundary>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              background: 'white',
              width: '100%',
            }}
            paddingBottom={4}
            paddingTop={4}
          >
            <Stack spacing={3} direction="row" marginLeft={12}>
              <ButtonBase onClick={onClickNext}>
                <Box
                  sx={{
                    background: '#474545',
                    color: '#FFFFFF',
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '24px',
                    borderRadius: '8px',
                  }}
                  padding={3}
                >
                  {answerState.questionIndex === answerState.answers.length - 1 ? 'Save' : 'Save and Next'}
                </Box>
              </ButtonBase>
              <ButtonBase onClick={reviewLater}>
                <Box
                  sx={{
                    background: '#474545',
                    color: '#FFFFFF',
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '24px',
                    borderRadius: '8px',
                  }}
                  padding={3}
                >
                  {currentAnswer.markedForReview ? 'Reviewed' : 'Review Later'}
                </Box>
              </ButtonBase>
              <ButtonBase onClick={clearSection}>
                <Box
                  sx={{
                    background: '#474545',
                    color: '#FFFFFF',
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '24px',
                    borderRadius: '8px',
                  }}
                  padding={3}
                >
                  Clear Section
                </Box>
              </ButtonBase>
            </Stack>
            <Stack spacing={3} direction="row" marginRight={6}>
              <Button
                variant="contained"
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '24px',
                  borderRadius: '8px',
                  textTransform: 'none',
                }}
                disabled={answerState.questionIndex === 0}
                onClick={onClickPrevious}
              >
                <ArrowBackIcon sx={{ height: '20px', width: '20px', marginRight: '4px' }} />
                Previous
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '24px',
                  borderRadius: '8px',
                  textTransform: 'none',
                }}
                disabled={answerState.questionIndex === answerState.answers.length - 1}
                onClick={onClickNext}
              >
                Next
                <ArrowForwardIcon sx={{ height: '20px', width: '20px', marginLeft: '4px' }} />
              </Button>
            </Stack>
          </Box>
        </Box>
        <ErrorBoundary>
          <RightPane
            answersState={birdviewState}
            onAction={onAction}
            selectedQuestionIndex={answerState.questionIndex}
            isTestSubmitting={isTestSubmitting}
          />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

const MemoizedTestAreaView = memo(TestAreaView);
MemoizedTestAreaView.displayName = 'MemoizedTestAreaView';

export { MemoizedTestAreaView as TestAreaView };
